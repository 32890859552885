<template>
  <section>
    <base-section
      :header="t('homepage.about-us-section.header')"
      :secondHeader="t('homepage.about-us-section.second-header')"
      :text="t('homepage.about-us-section.text')"
      class="central-item"
    >
      <img
        src="@/assets/images/common/about-us.jpg"
        alt="office"
        class="central-item large"
      />
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';

export default {
  components: {
    BaseSection
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
section {
  position: relative;

  text-align: center;
  justify-content: center;
  margin-bottom: 100px;
}

.custom-section img {
  position: relative;

  top: 100px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);

  object-fit: cover;
  width: 120%;
  height: 439px;

  border-radius: 250px;
}

@media (max-width: 1024px), (orientation: landscape) {
  .custom-section img {
    height: 230px;
    width: 100%;
  }
}
</style>
