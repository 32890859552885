<template>
  <header-section
    :header="t('homepage.header-section.header')"
    :text="t('homepage.header-section.text')"
    :backgroundImageUrl="imageUrl"
    :statistics="statistics"
  >
    <template #button>
      <custom-button
        :buttonText="t('homepage.header-section.button')"
        buttonLink="/company"
      />
    </template>
    <div id="ms-logos">
      <a
        href="https://learn.microsoft.com/en-us/partner-center/membership/silver-gold-membership-competency"
      >
        <img
          src="@/assets/images/homepage/logos/ms-gold-partner.png"
          alt="Microsoft Gold Partner"
        />
      </a>
      <a href="https://partner.microsoft.com/en-us/partnership/solutions-partner">
        <img
          src="@/assets/images/homepage/logos/ms-solutions-partner.svg"
          alt="Microsoft Solutions Partner"
        />
      </a>
    </div>
  </header-section>
  <about-us-section />
  <services-section />
  <technology-section />
  <business-model-section />
  <case-studies-section />
  <lets-talk-section id="lets-talk-section" />
  <testimonials-section />
  <footer-section />
</template>

<script>
import AboutUsSection from '@/components/sections/homepage/AboutUsSection.vue';
import ServicesSection from '@/components/sections/homepage/ServicesSection.vue';
import TechnologySection from '@/components/sections/common/TechnologySection.vue';
import BusinessModelSection from '@/components/sections/homepage/BusinessModelSection.vue';
import CaseStudiesSection from '@/components/sections/homepage/CaseStudiesSection.vue';
import LetsTalkSection from '@/components/sections/common/LetsTalkSection.vue';
import TestimonialsSection from '@/components/sections/homepage/TestimonialsSection.vue';
import CustomButton from '@/components/modules/CustomButton.vue';
import { useHead } from '@unhead/vue';

export default {
  components: {
    AboutUsSection,
    ServicesSection,
    TechnologySection,
    BusinessModelSection,
    CaseStudiesSection,
    LetsTalkSection,
    TestimonialsSection,
    CustomButton
  },
  setup() {
    useHead({
      meta: [
        {
          name: 'description',
          content:
            'Deep Network GmbH: Empowering businesses with cutting-edge enterprise solutions, cloud analytics, and Azure Synapse Analytics expertise. Drive digital transformation with our certified experts.'
        }
      ]
    });
  },
  props: {},
  data() {
    return {
      imageUrl: require('@/assets/images/common/header/hero.png')
    };
  },
  computed: {
    statistics() {
      return [
        {
          header: this.t('homepage.header-section.statistics[0].header'),
          text: this.t('homepage.header-section.statistics[0].text')
        },
        {
          header: this.t('homepage.header-section.statistics[1].header'),
          text: this.t('homepage.header-section.statistics[1].text')
        }
      ];
    }
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
#ms-logos {
  display: flex;
  flex-direction: column;
  gap: 5%;

  position: absolute;
  right: 0;
  top: 55%;

  aspect-ratio: 1.1/1;
  width: 12%;
  min-width: 150px;
  max-width: 200px;

  a {
    display: flex;
  }

  img {
    width: 100%;
  }
}

#lets-talk-section {
  margin-top: -150px;
}

@media (max-width: 1200px) {
  #ms-logos {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    margin-top: 25px;

    position: static;
    height: 71px;
    width: 100%;
    max-width: none;
  }

  #ms-logos img {
    height: 71px;
    width: auto;
  }
}
</style>
