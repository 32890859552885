<template>
  <main>
    <router-view />
    <CookieConsentBanner />
  </main>
</template>

<script>
import { useI18n } from 'vue-i18n';
import CookieConsentBanner from '@/components/modules/CookieConsentBanner.vue';

export default {
  components: {
    CookieConsentBanner
  },
  setup() {
    {
      const { t, tm } = useI18n();
      return { t, tm };
    }
  },
  provide() {
    return {
      t: this.t,
      tm: this.tm
    };
  }
};
</script>

<style>
/* Inter Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --darker-blue: #072249;
  --dark-blue: #0062af;
  --light-blue: #f7fbff;
  --text-grey: #595959;
  --light-grey: #999999;
  --shadow-color: #deebf7;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: 'Inter', 'Times New Roman', sans-serif;
}

body {
  overflow-x: hidden;
}

.central-item {
  /* border: 1px red solid; */
  max-width: 960px;
  width: 100%;

  margin-right: auto;
  margin-left: auto;

  padding-right: 10px;
  padding-left: 10px;
}

.central-item.large {
  max-width: 1200px;
}

.left-aligned {
  text-align: left;
}

/* Card */
.card {
  padding: 40px;

  border-radius: 5%;

  background-color: white;
  box-shadow: 15px 15px 15px var(--shadow-color);

  text-align: left;
}
</style>
