<template>
  <section>
    <base-section
      :header="t('homepage.testimonials-section.header')"
      :secondHeader="t('homepage.testimonials-section.second-header')"
    >
      <carousel
        v-bind="config"
        class="central-item large"
      >
        <slide
          v-for="testimonial in testimonials"
          :key="testimonial.title"
        >
          <div class="testimonial card">
            <img
              :src="testimonial.imageUrl"
              alt="profile-picture"
            />
            <header>{{ testimonial.name }}</header>
            <p class="title">{{ testimonial.title }} @ {{ testimonial.company }}</p>
            <p>{{ testimonial.content }}</p>
          </div>
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel>
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
  components: {
    BaseSection,
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      config: {
        itemsToShow: 1,
        snapAlign: 'center',
        wrapAround: true,
        transition: 1500,
        breakpoints: {
          850: {
            itemsToShow: 3
          }
        },
        mouseDrag: false,
        touchDrag: false,
        autoplay: 4000
      },
      testimonials: [
        {
          imageUrl: require('@/assets/images/homepage/testimonials/walter-sullivan.jpeg'),
          name: 'Walter Sullivan',
          title: 'CTO',
          company: 'Designated Driver.AI',
          content:
            'The expertise Deep Network brings to cloud solution development is outstanding. Their cost efficiency has enabled us to scale effectively while staying within our budget as a startup.'
        },
        {
          imageUrl: require('@/assets/images/homepage/testimonials/gomti-mehta.jpg'),
          name: 'Gomti Mehta',
          title: 'Solutions Engineer',
          company: 'Github Inc.',
          content:
            'Deep Network excels in cloud software development and DevOps practices. Their professionalism and ability to deliver results make working with them a pleasure'
        },
        {
          imageUrl: require('@/assets/images/homepage/testimonials/gulay-vural.jpeg'),
          name: 'Gülay Vural',
          title: 'Director of MWC Development',
          company: 'Microsoft',
          content:
            'Deep Network’s proficiency in software development on MS Azure is impressive. Their ability to adapt and innovate makes working with them a remarkable experience'
        }
      ]
    };
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
section {
  text-align: center;

  background-color: var(--light-blue);
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  user-select: none;

  width: 380px;
}

.testimonial img {
  height: 74px;
  width: 74px;

  margin-bottom: 1rem;

  object-fit: cover;

  clip-path: circle(45%);
}

.testimonial header {
  margin-bottom: 0.1rem;

  color: var(--dark-blue);
  font-size: 25px;
  font-weight: bolder;
}

.testimonial .title {
  margin-bottom: 1rem;

  font-size: 16px;
  font-weight: 400;
}

.testimonial p {
  color: var(--text-grey);
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
}

.carousel__slide {
  margin-top: 48px;
  margin-bottom: 50px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev,
.carousel__slide.carousel__slide--next {
  opacity: 0.5;
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1);
}

@media (max-width: 450px) {
  .testimonial {
    width: 85%;
  }
}
</style>
