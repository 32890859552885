<template>
  <nav>
    <ul class="nav-links hide-on-mobile">
      <li>
        <router-link to="/"
          ><img
            class="logo"
            src="@/assets/images/common/header/logo.svg"
            alt="Deep Network"
        /></router-link>
      </li>
      <li>
        <router-link to="/services">{{ t('common.navigation.services') }}</router-link>
      </li>
      <li>
        <router-link to="/company">{{ t('common.navigation.company') }}</router-link>
      </li>
      <li>
        <router-link to="/case-studies">{{ t('common.navigation.case-studies') }}</router-link>
      </li>
      <li>
        <router-link to="/technologies">{{ t('common.navigation.technologies') }}</router-link>
      </li>
      <li>
        <router-link to="/faq">{{ t('common.navigation.faq') }}</router-link>
      </li>
      <!-- <li><router-link to="/career">{{ t('common.navigation.career') }}</router-link></li> -->
      <li><language-switcher class="hide-on-mobile"></language-switcher></li>
    </ul>
    <div class="sidebar-wrapper">
      <router-link to="/">
        <img
          class="logo"
          src="@/assets/images/common/header/logo.svg"
          alt="Deep Network"
        />
      </router-link>
      <svg
        class="clickable"
        @click="sideBarUpdate"
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#e8eaed"
      >
        <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
      </svg>
      <ul
        v-if="sideBarOpen"
        class="sidebar"
      >
        <li>
          <svg
            class="clickable"
            @click="sideBarUpdate"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
            />
          </svg>
        </li>
        <li>
          <router-link to="/">{{ t('common.navigation.home') }}</router-link>
        </li>
        <li>
          <router-link to="/services">{{ t('common.navigation.services') }}</router-link>
        </li>
        <li>
          <router-link to="/company">{{ t('common.navigation.company') }}</router-link>
        </li>
        <li>
          <router-link to="/case-studies">{{ t('common.navigation.case-studies') }}</router-link>
        </li>
        <li>
          <router-link to="/technologies">{{ t('common.navigation.technologies') }}</router-link>
        </li>
        <li>
          <router-link to="/faq">{{ t('common.navigation.faq') }}</router-link>
        </li>
        <!-- <li><router-link to="/career">{{ t('common.navigation.career') }}</router-link></li> -->
        <li>
          <language-switcher>{{ t('common.navigation.language') }}</language-switcher>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  components: {
    LanguageSwitcher
  },
  data() {
    return {
      sideBarOpen: false
    };
  },
  methods: {
    sideBarUpdate() {
      this.sideBarOpen = !this.sideBarOpen;
    }
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  display: flex;
}

a {
  text-decoration: none;
  font-size: 1rem;
  color: white;
}

li {
  margin: 20px;
}

.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 1% 2%;
}

.nav-links li:first-child {
  margin-right: auto;
}

.nav-links li:last-child {
  margin-left: auto;
}

a:hover {
  text-decoration: underline;
}

.sidebar-wrapper {
  display: none;
  justify-content: space-between;
  align-items: center;

  margin: 4%;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: white; */

  height: 100dvh;
  width: 250px;
  z-index: 999;
  backdrop-filter: blur(15px);
  background-color: rgba(34, 73, 100, 0.2);
  box-shadow: -10px 0 -10px rgba(0, 0, 0, 0.1);
}

.sidebar li:first-child {
  align-self: flex-end;
  text-align: right;

  margin-top: 30px;
  /* filter: invert(100%); */
}

.sidebar li {
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 0;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}

.clickable:hover {
  cursor: pointer;
}

#language-selector {
  display: flex;
}

#language-selector select {
  background: none;
  border: none;
  color: black;
  font-size: 0;
}

@media (max-width: 950px) {
  .hide-on-mobile {
    display: none;
  }
  .sidebar-wrapper {
    display: flex;
  }
}

@media (max-width: 450px) {
  .sidebar-wrapper {
    height: 30px;
  }

  .sidebar-wrapper img {
    object-fit: contain;

    height: 30px;
  }

  .sidebar {
    width: 100%;
  }
}
</style>
