<template>
  <section>
    <base-section
      :header="t('homepage.case-studies.header')"
      :secondHeader="t('homepage.case-studies.second-header')"
      :text="t('homepage.case-studies.text')"
      class="central-item"
    >
      <card-group :cards="cards" />
      <custom-button
        class="custom-button"
        :buttonText="t('homepage.case-studies.button-text')"
        buttonLink="/case-studies"
      />
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';
import CustomButton from '@/components/modules/CustomButton.vue';
import CardGroup from '@/components/modules/CardGroup.vue';

export default {
  components: {
    BaseSection,
    CustomButton,
    CardGroup
  },
  data() {
    return {
      cards: this.$store.state.caseStudies.slice(0, 2)
    };
  },
  provide() {
    return {
      toLink: '/case-studies/'
    };
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
section {
  align-items: center;
  text-align: center;

  position: relative;

  z-index: 3;
}

.custom-button {
  align-self: center;
}
</style>
