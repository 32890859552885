<template>
  <label
    class="switch"
    @click="toggle"
  >
    <input
      type="checkbox"
      :checked="checked === true"
      :disabled="disabled"
      @click.stop
    />
    <span
      class="slider round"
      :class="{ active: checked, disabled: disabled }"
    ></span>
  </label>
</template>
<script>
export default {
  name: 'CustomToggle',
  props: {
    checked: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: ['toggleValue'],
  methods: {
    toggle() {
      this.$emit('toggleValue', !this.checked);
    }
  }
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  min-width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider.active {
  background-color: #2196f3;
}
.slider.disabled {
  filter: opacity(0.6);
}

.slider.active:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@media (max-width: 450px) {
  .switch {
    width: 30px;
    min-width: 30px;
    height: 17px;
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: -12px;
    bottom: 2px;
  }
}
</style>
