<template>
  <div class="custom-section">
    <header
      v-if="!!header"
      v-html="sanitizedHeader"
    />
    <h1
      v-if="!!secondHeader"
      v-html="sanitizedSecondHeader"
    />
    <p
      v-if="!!text"
      v-html="sanitizedText"
    />
    <slot />
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    },
    secondHeader: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    sanitizedHeader() {
      return DOMPurify.sanitize(this.header);
    },
    sanitizedSecondHeader() {
      return DOMPurify.sanitize(this.secondHeader);
    },
    sanitizedText() {
      return DOMPurify.sanitize(this.text);
    }
  }
};
</script>

<style scoped>
.custom-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  padding-top: 5.5rem;
  padding-bottom: 5.5rem;

  &.left-aligned {
    align-items: flex-start;
  }
}

header,
:slotted(header) {
  color: var(--dark-blue);

  font-weight: bold;
  font-size: 22px;
}

h1,
:slotted(h1) {
  font-size: 42px;

  max-width: 70%;

  margin: 1rem 0;
}

p,
:slotted(p) {
  padding: 1rem 0;

  color: var(--text-grey);
  font-weight: 200;
  font-size: 22px;
  line-height: 1.5;
}

@media (max-width: 960px) {
  .custom-section {
    padding: 50px 20px;
  }
}

@media (max-width: 450px) {
  header {
    font-size: 16px;
  }

  h1 {
    max-width: 100%;

    font-size: 32px;
  }

  p {
    font-size: 20px;
  }
}
</style>
