<template>
  <div class="carousel-wrapper">
    <div
      v-for="c in 2"
      :key="c"
      class="carousel"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.carousel-wrapper {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  width: 100%;
}

@keyframes slide {
  from {
    transform: translate(0);
  }

  to {
    transform: translate(-100%);
  }
}

.carousel {
  display: flex;
  flex-direction: row;

  padding-top: 10px;
  padding-bottom: 10px;

  animation: 35s slide infinite linear;
}
</style>
