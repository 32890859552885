<template>
  <section :style="cssProps">
    <base-section
      :secondHeader="t('common.lets-talk-section.second-header')"
      :text="t('common.lets-talk-section.text')"
      class="central-item"
    >
      <custom-button
        class="classic-button"
        :buttonText="t('common.lets-talk-section.button-text')"
        buttonLink="mailto:info@deepnetwork.com"
        :isLink="false"
      />
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';
import CustomButton from '@/components/modules/CustomButton.vue';

export default {
  components: {
    BaseSection,
    CustomButton
  },
  data() {
    return {
      backgroundImageUrl: require('@/assets/images/common/lets-talk.jpg')
    };
  },
  computed: {
    cssProps() {
      return {
        '--background-image-url': `url(${this.backgroundImageUrl})`
      };
    }
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
section {
  text-align: center;
  position: relative;
  background-image:
    linear-gradient(180deg, hsla(215.5, 82.5%, 15.7%, 0.8), hsla(215.5, 82.5%, 15.7%, 1)),
    var(--background-image-url);
  background-size: cover;
  color: white;
  padding-top: 350px;
}

section::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 300px;
  content: '';
  background-color: white;
  border-radius: 100% 0 100% 0 / 0 0 100% 100%;
  border: 1px solid;
}

section * {
  color: white;
}

.classic-button {
  align-self: center;
}

@media (max-width: 450px) {
  section::before {
    border-radius: 100% 0 100% 0 / 0 64% 36% 100%;
  }
}
</style>
