<template>
  <footer>
    <router-link
      to="/"
      id="footer-logo"
    >
      <img
        src="@/assets/images/common/header/logo.svg"
        alt="Deep Network"
      />
    </router-link>
    <div id="footer-texts">
      <p id="footer-description">
        {{ t('common.footer.text1') }}
      </p>
      <br />
      <div
        class="footer-company-info"
        id="footer-legal-description"
      >
        <p>{{ t('common.footer.managing-director') }}</p>
        <p>{{ t('common.footer.domicile') }}</p>
        <p>{{ t('common.footer.registry') }}</p>
        <p>{{ t('common.footer.id-no') }}</p>
      </div>
    </div>
    <nav id="footer-site-map">
      <h1>SITEMAP</h1>
      <ul class="site-map-links">
        <li
          v-for="route in siteMap"
          :key="route.key"
        >
          <router-link :to="route.path">
            {{ t(`common.footer.site-map.${route.key}`) }}
          </router-link>
        </li>
      </ul>
    </nav>
    <address
      v-for="(office, index) in offices"
      :key="index"
      :id="`footer-address-${office.country.toLowerCase()}`"
      class="footer-address"
    >
      <h1>{{ office.country }}</h1>
      <div class="footer-address-details">
        <p>{{ office.address.name }}</p>
        <p>{{ office.address.street }}</p>
        <p>{{ office.address.city }}</p>
      </div>
      <div class="footer-address-contact">
        <div
          class="contact-info"
          v-for="(contact, cIndex) in office.contacts"
          :key="cIndex"
        >
          <img
            :src="getIcon(contact.type)"
            :alt="`${contact.type}-icon`"
          />
          <span>{{ contact.value }}</span>
        </div>
      </div>
    </address>

    <p class="copyright">
      © {{ currentYear }} Copyright Deep Network GmbH. All Rights Reserved. The text, images,
      graphics, sound files, animation files, video files and their arrangement on Deep Network
      Internet sites are all subject to Copyright and other intellectual property protection. These
      objects may not be copied for commercial use or distribution, nor may these objects be
      modified or reposted to other sites. Some Deep Network Internet sites also contain material
      that is subject to the copyright rights of their providers.
    </p>
  </footer>
</template>

<script>
export default {
  inject: {
    t: {
      from: 't'
    }
  },
  data() {
    return {
      siteMap: [
        { path: '/', key: 'home' },
        { path: '/services', key: 'services' },
        { path: '/company', key: 'company' },
        { path: '/case-studies', key: 'case-studies' },
        { path: "/technologies", key: 'technologies' },
        { path: '/faq', key: 'faq' },
        { path: '/impressum', key: 'impressum' }
        // { path: "/career", key: 'career' }, // Uncomment when needed
      ],
      offices: [
        {
          country: 'GERMANY',
          address: {
            name: 'Deep Network GmbH',
            street: 'Zeppelinstr. 73',
            city: 'DE-81669 München'
          },
          contacts: [
            { type: 'phone', value: '+49 (0) 89 2316 6638' },
            { type: 'email', value: 'info@deepnetwork.com' }
          ]
        },
        {
          country: 'USA',
          address: {
            name: 'Deep Network Corp.',
            street: '1321 Upland Dr. Apt #2133',
            city: 'Houston, TX 77043'
          },
          contacts: [
            { type: 'phone', value: '+1 425 247 0842' },
            { type: 'email', value: 'info@deepnetwork.com' }
          ]
        }
      ]
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    getIcon(type) {
      return require(`@/assets/images/common/footer/${type}.svg`);
    }
  }
};
</script>

<style scoped>
footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 100px 120px;

  background-color: var(--darker-blue);

  color: white;
  font-weight: 200;
  font-size: 16px;
}

footer * {
  font-size: 16px;
  line-height: 1.5;
}

footer > *:not(:first-child) {
  flex-grow: 1;

  margin-right: 20px;
}

footer > * {
  margin-bottom: 30px;
}

#footer-logo {
  width: 100%;
}

#footer-texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 400px;
}

#footer-site-map {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.site-map-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  flex: 1;
}

#footer-site-map a {
  margin-top: 1rem;

  color: white;
  text-decoration: none;
  font-size: 14px;
}

#address-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-company-info {
  display: flex;
  flex-direction: column;
}

.footer-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 246px;
  margin-bottom: 30px;
}

.footer-address-details {
  margin-top: 10px;
}

.footer-address-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  justify-self: flex-end;
  margin-top: 30px;
}

.footer-address-contact img {
  filter: invert(100%);
}

.contact-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 207px;

  margin-top: 10px;
}

.contact-info img {
  margin-right: 10px;
}

.copyright {
  margin-top: 30px;

  font-size: 10px;
  color: var(--light-grey);
}

@media (max-width: 584px) {
  * {
    text-align: center;
  }

  footer {
    padding: 5% 10%;
  }

  #footer-site-map {
    justify-content: center;
    align-items: center;
  }

  .site-map-links {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
  }

  .footer-address {
    height: 200px;
  }

  .footer-address-contact {
    align-items: center;
  }
}
</style>
