<template>
  <div class="photo-card-group">
    <div
      v-for="card in cards"
      :key="card.title"
      class="photo-card"
    >
      <img
        v-if="showImage"
        :src="card.imageUrl"
        loading="lazy"
        alt="image"
      />
      <div class="photo-card-metadata">
        <time
          v-if="false"
          datetime="2020-01-01"
        >
          {{ card.date }}
        </time>
        <h2>{{ card.title }}</h2>
        <router-link :to="toLink + card.slug">{{ t('common.navigation.read-more') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    toLink: { from: 'toLink' },
    t: { from: 't' }
  },
  props: {
    cards: {
      type: Array,
      required: true
    },
    showImage: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style scoped>
.photo-card-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  gap: 30px;
}

.photo-card {
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: 570px;
  min-width: min(330px, 100%);

  margin-bottom: 15px;

  border-radius: 15px;
  border: 1px solid transparent;

  box-shadow: 10px 10px 10px var(--shadow-color);

  transition: all 0.2s;
}

.photo-card:hover {
  border: 1px solid var(--dark-blue);
  transform: scale(1.02);
}

.photo-card:hover ~ .photo-card:not(:hover) {
  transform: scale(0.99);
}

.photo-card:has(~ .photo-card:hover) {
  transform: scale(0.99);
}

.photo-card img {
  object-fit: cover;

  height: 253px;
  width: 100%;

  border-radius: 15px 15px 0 0;
}

.photo-card-metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100px;

  text-align: left;

  margin: 24px 40px;
}

.photo-card-metadata time {
  font-size: 16px;
  font-weight: 200;
  color: var(--light-grey);
}

.photo-card-metadata h2 {
  font-size: 18px;
}

.photo-card-metadata a {
  font-size: 16px;
  text-decoration: none;
  color: var(--dark-blue);
}
</style>
