import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createStore } from 'vuex';
import i18n from './i18n';

import App from './App.vue';
import HomePage from './pages/HomePage.vue';

import HeaderSection from './components/sections/common/HeaderSection.vue';
import FooterSection from './components/sections/common/FooterSection.vue';

// Load store
import caseStudiesData from '@/assets/data/case-studies.json';
import technologiesData from '@/assets/data/technologies.json';

const store = createStore({
  state() {
    return {
      caseStudies: caseStudiesData,
      technologies: technologiesData
    };
  }
});

// Load router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage },
    { path: '/services', component: () => import('./pages/ServicesPage.vue') },
    { path: '/company', component: () => import('./pages/CompanyPage.vue') },
    { path: '/technologies', component: () => import('./pages/TechnologiesPage.vue') },
    { path: '/technologies/:slug', component: () => import('./pages/TechnologiesContentPage.vue') },
    { path: '/case-studies', component: () => import('./pages/CaseStudyPage.vue') },
    { path: '/case-studies/:slug', component: () => import('./pages/CaseStudyContentPage.vue') },
    { path: '/faq', component: () => import('./pages/FaqPage.vue') },
    { path: '/impressum', component: () => import('./pages/ImpressumPage.vue') },
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash }; // Scroll to the element with the matching hash
    }
    return { top: 0 };
  }
});

// Create app
const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head);
app.use(store);
app.use(i18n);

app.component('header-section', HeaderSection).component('footer-section', FooterSection);

app.mount('#app');
