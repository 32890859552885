<template>
  <section>
    <base-section
      :header="t('homepage.services-section.header')"
      :secondHeader="t('homepage.services-section.second-header')"
      :text="t('homepage.services-section.text')"
      class="central-item"
    >
      <div id="service-grid">
        <div
          class="services-box card"
          v-for="service in services"
          :key="service.toggle"
        >
          <h1>{{ service.header }}</h1>
          <p>{{ service.text }}</p>
          <router-link
            :to="{
              path: '/services',
              query: { selectedToggle: service.toggle },
              hash: '#service-section-content'
            }"
          >
            {{ t('common.link-learn-more') }}
          </router-link>
        </div>
      </div>
      <custom-button
        class="custom-button"
        :buttonText="t('common.link-learn-more')"
        buttonLink="/services"
      />
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';
import CustomButton from '@/components/modules/CustomButton.vue';

export default {
  components: {
    BaseSection,
    CustomButton
  },
  inject: {
    t: {
      from: 't'
    }
  },
  computed: {
    services() {
      const toggles = ['cloud', 'ai', 'big-data', 'trainings'];

      return toggles.map((toggle, index) => ({
        header: this.t(`homepage.services-section.services[${index}].header`),
        text: this.t(`homepage.services-section.services[${index}].text`),
        toggle
      }));
    }
  }
};
</script>

<style scoped>
section {
  align-items: center;
  background-color: var(--light-blue);

  text-align: center;
}

.custom-button {
  align-self: center;
}

#service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  padding: 3% 3%;
  gap: 20px;
}

.services-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  padding: 0 3rem;
  min-height: 304px;

  border: 1px solid transparent;
  transition: all 0.5s;
}

.services-box:hover {
  border: 1px solid var(--dark-blue);
  transform: scale(1.02);
}

.services-box:hover ~ .services-box:not(:hover) {
  transform: scale(0.99);
}

.services-box:has(~ .services-box:hover) {
  transform: scale(0.99);
}

.services-box h1 {
  color: var(--dark-blue);
  font-size: 25px;
}

.services-box p {
  margin: 0;
  padding: 10px 0;

  font-size: 18px;
  font-weight: 300;

  color: #595959;
}

.services-box a {
  padding-bottom: 10px;

  font-size: 16px;
  color: var(--dark-blue);
  text-decoration: none;
}
</style>
