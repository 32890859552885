<template>
  <a
    v-if="isExternal"
    :href="buttonLink"
    class="classic-btn"
  >
    {{ buttonText }}
  </a>
  <router-link
    v-else-if="isLink"
    class="classic-btn"
    :to="buttonLink"
  >
    {{ buttonText }}
  </router-link>
  <a
    v-else
    class="classic-btn"
    :href="buttonLink"
  >
    {{ buttonText }}
  </a>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: false,
      default: null
    },
    isLink: {
      type: Boolean,
      required: false,
      default: true
    },
    isExternal: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped>
.classic-btn,
.classic-btn:not(:active) {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--dark-blue);
  color: white;
  text-decoration: none;

  width: 192px;
  height: 45px;
  border-radius: 25px;
  border: none;

  font-size: 1.1rem;
  font-weight: 200;

  margin: 20px;
}

.classic-btn:active {
  transform: scale(0.97);
}

.classic-btn.centered {
  margin: 20px auto;
}
</style>
