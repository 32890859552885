<template>
  <section>
    <base-section
      :header="t('homepage.business-section.header')"
      :secondHeader="t('homepage.business-section.second-header')"
      :text="t('homepage.business-section.text')"
      class="central-item"
    >
      <div class="model-grid">
        <div
          class="model-box"
          v-for="reason in reasons"
          :key="reason.imageUrl"
        >
          <div class="image-background">
            <img
              :src="reason.imageUrl"
              alt="icon"
            />
          </div>
          <p>{{ reason.text }}</p>
        </div>
      </div>
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';

export default {
  components: {
    BaseSection
  },
  inject: {
    t: {
      from: 't'
    }
  },
  computed: {
    reasons() {
      return Array.from({ length: 6 }, (_, index) => ({
        imageUrl: require(`@/assets/images/homepage/business-model/${index + 1}.svg`),
        text: this.t(`homepage.business-section.reasons[${index}]`)
      }));
    }
  }
};
</script>

<style scoped>
section {
  background-color: var(--light-blue);
}

.model-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.model-box {
  padding: 1rem;

  background-color: white;

  border-radius: 10px;
  isolation: isolate;
}

.model-box .image-background {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;

  border-radius: 50%;
  background-color: var(--dark-blue);
}

.model-box img {
  filter: invert(100%);
}

.model-box p {
  padding: 1rem 0;
  color: var(--text-grey);

  line-height: 1.5;

  font-size: 18px;
  font-weight: 300;
}
</style>
