<template>
  <div class="language-selector">
    <slot />
    <img
      :src="selectedLanguageImage"
      alt="flag"
    />
    <img
      src="@/assets/images/common/header/arrow.svg"
      alt="down-arrow"
    />
    <select
      @change="setLanguage"
      v-model="locale"
    >
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </select>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  name: 'LanguageSwitcher',
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  data() {
    return {
      storedLanguage: localStorage.getItem('lang')
    };
  },
  created() {
    if (this.storedLang) {
      this.locale = this.storedLanguage;
    }
  },
  methods: {
    setLanguage(event) {
      const newLanguage = event.target.value;
      this.locale = newLanguage;
      localStorage.setItem('lang', newLanguage);
    }
  },
  computed: {
    selectedLanguageImage() {
      return this.locale === 'en'
        ? require('@/assets/images/common/header/flag-en.svg')
        : require('@/assets/images/common/header/flag-de.svg');
    }
  },
  watch: {
    locale(newLocale) {
      localStorage.setItem('lang', newLocale.value);
    }
  }
};
</script>

<style scoped>
.language-selector {
  position: relative;
  display: flex;
  align-items: center;
  color: white;

  width: max-content;
}

.language-selector img {
  margin-left: 5px;
}

.language-selector select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  color: white;
  opacity: 0;
  position: absolute;
  right: 0;

  cursor: pointer;
}
</style>
