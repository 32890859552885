<template>
  <section>
    <base-section
      :header="t('common.technology-section.header')"
      :secondHeader="t('common.technology-section.second-header')"
      :text="t('common.technology-section.text')"
      class="central-item"
    >
      <image-carousel :images="this.technologyLogos" />
    </base-section>
  </section>
</template>

<script>
import BaseSection from '@/components/sections/common/BaseSection.vue';
import ImageCarousel from '@/components/modules/ImageCarousel.vue';

export default {
  components: {
    BaseSection,
    ImageCarousel
  },
  data() {
    return {
      technologyLogos: [
        {
          source: require('@/assets/images/homepage/technology/amazon-kinesis.svg'),
          alt: 'Amazon Kinesis'
        },
        {
          source: require('@/assets/images/homepage/technology/aws.svg'),
          alt: 'AWS'
        },
        {
          source: require('@/assets/images/homepage/technology/azure.svg'),
          alt: 'Azure'
        },
        {
          source: require('@/assets/images/homepage/technology/databricks.svg'),
          alt: 'Databricks'
        },
        {
          source: require('@/assets/images/homepage/technology/spark.svg'),
          alt: 'Spark'
        },
        {
          source: require('@/assets/images/homepage/technology/terraform.svg'),
          alt: 'Terraform'
        }
      ]
    };
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style scoped>
section {
  position: relative;
  overflow: hidden;
  align-items: center;

  text-align: center;

  z-index: 3;
}
</style>
