<template>
  <section :style="cssProps">
    <navigation-bar />
    <div class="content central-item large">
      <h1 v-html="sanitizedHeader" />
      <p v-html="sanitizedText" />
      <slot name="button" />
    </div>
    <slot>
      <div />
    </slot>
    <div
      v-if="statistics.length !== 0"
      class="header-statistic-box"
    >
      <template
        v-for="(st, index) in statistics"
        :key="st.header"
      >
        <div class="statistic">
          <h3 v-html="st.header" />
          <p v-html="st.text" />
        </div>
        <div
          v-if="index !== statistics.length - 1"
          class="statistic-spacer"
        />
      </template>
    </div>
    <default-carousel
      v-if="statistics.length !== 0"
      class="header-statistic-box show-on-mobile"
    >
      <template
        v-for="st in statistics"
        :key="st.header"
      >
        <div class="statistic">
          <h3>{{ st.header }}</h3>
          <p>{{ st.text }}</p>
        </div>
      </template>
    </default-carousel>
  </section>
</template>

<script>
import NavigationBar from '@/components/modules/NavigationBar.vue';
import DefaultCarousel from '@/components/modules/DefaultCarousel.vue';
import DOMPurify from 'dompurify';

export default {
  components: {
    NavigationBar,
    DefaultCarousel
  },
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImageUrl: {
      type: String,
      required: false,
      default: ''
    },
    isFull: {
      type: Boolean,
      required: false,
      default: true
    },
    statistics: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    cssProps() {
      return {
        '--background-image-url': `url(${this.backgroundImageUrl})`,
        '--section-height': this.isFull ? '100dvh' : '60dvh'
      };
    },
    sanitizedHeader() {
      return DOMPurify.sanitize(this.header);
    },
    sanitizedText() {
      return DOMPurify.sanitize(this.text);
    }
  }
};
</script>

<style scoped>
section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;

  background-size: cover;
  /* background-attachment: fixed;  */
  background-position: center;
  background-image:
    linear-gradient(180deg, hsla(210, 100%, 14%, 1), hsla(210, 100%, 14%, 0.8)),
    var(--background-image-url);

  min-height: var(--section-height);
  width: 100%;
}

nav {
  justify-self: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 5%;

  color: white;

  text-align: center;
}

.content h1 {
  font-size: 62px;
  font-weight: 600;

  padding-right: 10px;
  padding-left: 10px;
}

.content p {
  padding: 2rem;
  margin-right: 150px;
  margin-left: 150px;

  font-size: 22px;
  font-weight: 200;
}

.header-statistic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  width: 100%;
  margin-top: auto;
  padding: 1% 10%;
  backdrop-filter: blur(10px);
}

.header-statistic-box.show-on-mobile {
  display: none;
}

.statistic {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 105px;
  min-width: 200px;
  color: white;
}

.statistic h3 {
  text-align: left;
  font-size: 42px;
}

.statistic p {
  text-align: left;
  font-size: 22px;
  font-weight: 150;
}

.statistic-spacer {
  height: 70px;
  border-right: 1px solid white;
}

@media (max-width: 1200px) {
  .content p {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 970px) {
  .statistic-spacer {
    display: none;
  }

  .header-statistic-box {
    display: none;
    overflow: hidden;
    height: 128px;

    padding-right: 0;
    padding-left: 0;
    gap: 0;
  }

  .header-statistic-box.show-on-mobile {
    display: flex;
    justify-content: flex-start;
  }

  .statistic {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

    width: auto;

    padding: 0 10px;
  }

  .statistic h3 {
    font-size: 32px;
  }

  .statistic p {
    font-size: 18px;
  }

  .content h1 {
    font-size: 52px;

    width: 80%;
  }
}

@media (max-width: 750px) {
  .content h1 {
    font-size: 42px;

    width: 80%;
  }

  .content p {
    font-size: 21px;
  }
}

@media (max-width: 560px) {
  section {
    height: auto;
  }

  .content h1 {
    font-size: 32px;

    width: 80%;
  }

  .content p {
    font-size: 20px;
  }
}

/* @media (orientation: landscape) {
    section {
        height: auto;
    }
} */
</style>
