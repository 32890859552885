<template>
  <div
    v-if="displayCookieBanner"
    class="cookie-overlay"
  >
    <div class="cookie-banner">
      <template v-if="showPreferences">
        <div class="preference">
          <h2>{{ t('cookies.preferences.necessary.title') }}</h2>
          <div class="content">
            <p>{{ t('cookies.preferences.necessary.content') }}</p>
            <custom-toggle
              :checked="true"
              :disabled="true"
            />
          </div>
        </div>
        <div class="preference">
          <h2>{{ t('cookies.preferences.functionality.title') }}</h2>
          <div class="content">
            <p>{{ t('cookies.preferences.functionality.content') }}</p>
            <custom-toggle
              :checked="cookiePreferences.functionality"
              @toggle-value="cookiePreferences.functionality = !cookiePreferences.functionality"
            />
          </div>
        </div>
        <div class="preference">
          <h2>{{ t('cookies.preferences.tracking.title') }}</h2>
          <div class="content">
            <p>{{ t('cookies.preferences.tracking.content') }}</p>
            <custom-toggle
              :checked="cookiePreferences.analytics"
              @toggle-value="cookiePreferences.analytics = !cookiePreferences.analytics"
            />
          </div>
        </div>
        <div class="preference">
          <h2>{{ t('cookies.preferences.third-party.title') }}</h2>
          <div class="content">
            <p>{{ t('cookies.preferences.third-party.content') }}</p>
            <custom-toggle
              :checked="cookiePreferences.thirdParty"
              @toggle-value="cookiePreferences.thirdParty = !cookiePreferences.thirdParty"
            />
          </div>
        </div>
        <div class="cookie-banner-sticky-actions">
          <custom-button
            class="cookie-action-button"
            :buttonText="t('cookies.save-preferences')"
            @click="acceptCookies"
            :isLink="false"
          />
        </div>
      </template>
      <template v-else>
        <h1 class="cookie-heading">{{ t('cookies.header') }}</h1>
        <p>{{ t('cookies.message') }}</p>
        <div class="cookie-banner-actions">
          <custom-button
            class="cookie-action-button"
            :buttonText="t('cookies.accept')"
            @click="acceptCookies"
            :isLink="false"
          />
          <custom-button
            class="cookie-action-button"
            :buttonText="t('cookies.decline')"
            @click="rejectCookies"
            :isLink="false"
          />
          <custom-button
            class="cookie-action-button"
            :buttonText="t('cookies.change-preferences')"
            @click="togglePreferences"
            :isLink="false"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/modules/CustomButton.vue';
import CustomToggle from '@/components/modules/CustomToggle.vue';

export default {
  components: { CustomToggle, CustomButton },
  data() {
    return {
      cookiePreferences: {
        necessary: true, //always true,
        functionality: true,
        analytics: true,
        thirdParty: true
      },
      hasCookiePreference: !!localStorage.getItem('cookiePreferences'),
      showPreferences: false,
      acceptedAnalyticsCookies: true
    };
  },
  mounted() {
    const storedPreferences = localStorage.getItem('cookiePreferences');
    if (storedPreferences) {
      this.cookiePreferences = JSON.parse(storedPreferences);
      this.hasCookiePreference = true;

      if (this.cookiePreferences.analytics) {
        this.enableGoogleAnalytics();
      }
    } else {
      this.hasCookiePreference = false;
    }
  },
  computed: {
    displayCookieBanner() {
      return !this.hasCookiePreference;
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiePreferences', JSON.stringify(this.cookiePreferences));
      if (this.cookiePreferences.analytics) {
        this.enableGoogleAnalytics();
      }
      this.hasCookiePreference = true;
    },
    rejectCookies() {
      this.cookiePreferences = {
        necessary: true,
        preferences: false,
        analytics: false,
        marketing: false
      };
      localStorage.setItem('cookiePreferences', JSON.stringify(this.cookiePreferences));
      this.hasCookiePreference = true;
    },
    enableGoogleAnalytics() {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_ANALYTICS_ID}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID);
      };
    },
    togglePreferences() {
      this.showPreferences = !this.showPreferences;
    }
  },
  inject: {
    t: {
      from: 't'
    }
  }
};
</script>

<style>
.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.cookie-banner {
  width: 70%;
  max-height: 70%;
  overflow-y: auto;
  margin: auto;
  background: var(--darker-blue);
  color: white;
  box-shadow: 0 0 32px 0 rgb(0 0 0 / 37%);
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  gap: 2rem;

  .cookie-heading {
    margin-top: 3rem;
  }

  .cookie-action-button {
    margin: 0 !important;
    width: auto !important;
    padding: 8px 16px;
    cursor: pointer;
  }
}

.cookie-banner-actions {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.cookie-banner-sticky-actions {
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  background: var(--darker-blue);
}

.preference {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .content {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    white-space: pre-line;
  }
}

.preference:first-child {
  margin-top: 3rem;
}

@media (max-width: 450px) {
  .cookie-banner {
    font-size: 12px;
    padding: 0 2rem;
  }

  .cookie-action-button {
    font-size: 12px !important;
    height: 30px !important;
  }
}
</style>
